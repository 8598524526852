<template>
  <!-- 课程详情页---讨论部分 -->
  <div class="discuss-container">
    <!-- 留言 -->
    <p class="liuyan">
      <span class="csp" @click="dialogFormVisible = true">
        <i class="el-icon-edit"></i>留言
      </span>
    </p>
    <!-- 评论主体 -->
    <div v-for="(item,index) in discussList" :key="index">
      <Discarea :comment="item"></Discarea>
    </div>
    <!-- 无留言 -->
    <Empty :show="discussList && discussList.length === 0" :text="'暂无留言'"></Empty>
    <!-- 创建留言对话框 -->
    <el-dialog :visible.sync="dialogFormVisible" top="30vh" width="50%">
      <div slot="title" class="dialog-title flex-align">
        <img :src="downloadURL+userInfo.avatar" alt="" :onerror="$store.state.user.defaultAvatar" class="dialog-avatar">
      </div>
      <el-form :model="form">
        <el-form-item>
          <el-input type="textarea" :rows="5" resize="none" placeholder="写留言" v-model="form.textarea" :maxlength="255"
            show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogFormVisible = false">取 消</el-button> -->
        <el-button type="primary" :plain="true" @click="submitComment">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Discarea from "./course_discuss_area"
  export default {
    components: {
      Discarea
    },
    data() {
      return {
        discussList: [], //讨论列表
        dialogFormVisible: false, //留言弹窗状态
        form: { //表单
          textarea: '',
        },
        // userInfo:JSON.parse(sessionStorage.getItem('userinfo')), //个人信息
        userInfo: {}, //个人信息
        parentId: 0
      }
    },
    methods: {
      // 获取讨论列表
      async getDiscussList() {
        let courseId = this.$route.params.id
        let resData = await this.$Api.Course.getCommentList(courseId)
        this.discussList = resData.data
      },
      async submitComment() {
        let params = {
          courseId: this.$route.params.id,
          parentId: this.parentId,
          content: this.form.textarea,
        }
        // console.log(params);
        let resData = await this.$Api.Course.getcreate(params);
        if (resData.code === 200 && resData.msg === "请求成功") {
          this.$message({
            message: '提交留言成功',
            type: 'success'
          });
          this.dialogFormVisible = false;
          this.form.textarea = "";
          this.getDiscussList();
        } else {
          this.$message.error(resData.msg);
        }
      }

    },
    mounted() {
      this.userInfo = this.$store.state.user.userInfo;
      this.getDiscussList()
    },
    activated() {},
    deactivated() {}
  }
</script>

<style lang="less" scoped>
  /*媒体查询*/
  /*当页面大于1200px 时，大屏幕，主要是PC 端*/
  @media (min-width: 1200px) {
    .discuss-container {
      width: 1200px;
    }
  }

  /*在992 和1199 像素之间的屏幕里，中等屏幕，分辨率低的PC*/
  @media (min-width: 992px) and (max-width: 1199px) {
    .discuss-container {
      width: 950px;
    }
  }

  .discuss-container {
    overflow: hidden;
    margin: 0 auto;
    background: #fff;
    padding-bottom: 20px;
  }

  .liuyan {
    text-align: right;
    margin: 25px 25px 15px 0;
    font-size: 16px;
  }

  .dialog-title {
    .dialog-avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 17px;
    }

    p {
      font-size: 16px;
    }
  }
</style>