<template>
<!-- 课程详情页---作业部分 -->
  <div class="course-task">
    <div class="task" v-for="(item, index) in questionlist" :key="index">
      <!-- 标题 -->
      <div class="task-title">{{ item.title.workname }}</div>
      <!-- 右侧按钮 -->
      <div class="task-gap flex-align-right">
        <!-- <div class="task-introduce wes">通知课第三讲【方海光：互联网+背景下的在线课堂教学】课堂小作业一</div> -->
        <div class="task-btn">
          <!--其他身份-->
          <button class="btn csp" 
                  :class="item.btnStatus == 3?'btn-finish':''"
                  v-if="!isExpert"
                  @click="checkWork(item.id, item.type, item.themeId, item.btnStatus)">
            {{item.btnStatus == 1 ? "查看" : item.btnStatus == 2? "去完成" : "已完成"}}
          </button>
          <!-- 专家 -->
          <button class="btn csp" @click="godetail(item.themeId)" v-if="isExpert">
            查看
          </button>
        </div>
      </div>
      <!-- 时间和状态 -->
      <div class="task-time flex-align">
        {{ item.title.beginTime }}-{{ item.title.endTime }}
        <button class="status" 
                :class="item.status == 2?'doing':'' ">
          {{item.status == 1? "未开始": item.status == 2? "进行中" : item.status == 3? "已结束" : ""}}
        </button>
      </div>
    </div>
    <div class="pagination flex-align-around" v-if="questionlist.length >0 &&  questionlist">
      <pagination v-show="pages.total > 0" 
                  :total="pages.total" 
                  :page.sync="pages.currentPage" 
                  :limit.sync="pages.pageSize"
                  :pageSizes="[5,10,15]"
                  @pagination="handlePageChange">
      </pagination>
    </div>
    <!-- 无课节-->
    <Empty :show="questionlist && questionlist.length === 0" :text="'暂无作业'"></Empty>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // workStatus: 1, //自定义作业状态 1--查看 2--去完成 3--已完成
        questionlist: [], //作业列表
        isExpert: false, //当前用户的类型是否是专家，默认false
        pages:{ //分页
          currentPage: 1,
          pageSize:5,
          total:0,
        },
      };
    },
    methods: {
      // 翻页
      handlePageChange(data){
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        this.checkWorkList();
      },
      //专家查看作业跳转作业主题详情页面
      godetail(themeid) {
        this.$router.push({
          path: "/workManage",
          query: {
            themeId: themeid,
            // createId: JSON.parse(sessionStorage.getItem("userinfo")).id,
            createId: this.$store.state.user.userInfo.id,
          },
        });
      },
      /**
       *  学员查看作业
       *    判断作业状态
       *        1. 已结束||未开始(1.查看,当前时间>=作业结束时间) --> 跳转作业详情(无答案、不可编辑)
       *        2. 进行中&&未提交(2.去完成,作业结束时间>当前时间>=作业开始时间) --> 跳转作业详情(无答案、可编辑)
       *        3. 进行中&&已提交(3.已完成,当前时间<作业开始时间) --> 跳转作业详情(批改后)
       */
      checkWork(workid, type, themeId, status) {
        //判断按钮状态 跳转不同页面
        console.log(status);
        if (status == 1) {
          console.log("跳转作业详情,无答案、不可编辑");
          this.$router.push({
            path: "/view_empty",
            query: {
              questionId: workid,
              // userId: JSON.parse(sessionStorage.getItem("userinfo")).id,
              userId: this.$store.state.user.userInfo.id,
              userType: "student",
              type: "task",
              from:'course',
              prevId:this.$route.params.id,
              query:JSON.stringify(this.$route.query)
            },
          });
        } else if (status == 2) {
          console.log("跳转答题页面,无答案、可编辑");
          if (type === 2 || type === 3) {
            console.log("测试&问卷");
            this.$router.push({
              path: "/confirm",
              query: {
                questionId: workid,
                userType: "student",
                status,
              },
            });
          } else {
            console.log(status);
            this.$router.push({
              path: "/answer",
              query: {
                questionId: workid,
                userType: "student",
                status,
                prevId:this.$route.params.id,
                query:JSON.stringify(this.$route.query)
              },
            });
          }
        } else {
          console.log("跳转批改详情,批改后");
          this.$router.push({
            path: "/view",
            query: {
              questionId: workid,
              // userId: JSON.parse(sessionStorage.getItem("userinfo")).id,
              userId: this.$store.state.user.userInfo.id,
              userType: "student",
              prevId:this.$route.params.id,
              query:JSON.stringify(this.$route.query)
            },
          });
        }
      },
      //查询当前课程下的作业列表
      checkWorkList() {
        console.log('查询当前课程下的作业列表')
        let params = {
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
          bindingId: this.$route.params.id,
          // userId: JSON.parse(sessionStorage.getItem("userinfo")).id,
          userId: this.$store.state.user.userInfo.id,
          projectId: this.$route.query.proid,
        };
        this.$Api.Form.getWorkList(params)
          .then((value) => {
            // console.log("====", value);
            this.questionlist = value.data.list;
            this.pages.total = value.data.totalCount;
            let currentTime = new Date().getTime();
            /**
             *  作业状态: 1.未开始、2.进行中、3.已结束
             *  操作按钮状态: 1.查看、2.去完成、3.已完成
             */
            this.questionlist.map((item) => {
              item.title = JSON.parse(item.title);
              item.status =
                currentTime > new Date(item.startRestriction.replace(/-/g, '/')).getTime() &&
                currentTime < new Date(item.endRestriction.replace(/-/g, '/')).getTime() ?
                "2" :
                currentTime < new Date(item.startRestriction.replace(/-/g, '/')).getTime() ?
                "1" :
                currentTime > new Date(item.endRestriction.replace(/-/g, '/')).getTime() ?
                "3" :
                "4";
              item.btnStatus =
                item.status == 1 || item.status == 3 ?
                "1" :
                item.status == 2 && item.isAnswer == 0 ?
                "2" :
                item.status == 2 && item.isAnswer == 1 ?
                "3" :
                "";
            });
            // console.log("----", this.questionlist);
          })
          .catch((reason) => {
            console.log(reason);
          });
      },
    },
    mounted() {
      this.isExpert = this.$store.state.user.role == "expert";
      this.checkWorkList();
    }
  };
</script>

<style lang="less" scoped>
  /*媒体查询*/
  /*当页面大于1200px 时，大屏幕，主要是PC 端*/
  @media (min-width: 1200px) {
    .course-task {}
  }

  /*在992 和1199 像素之间的屏幕里，中等屏幕，分辨率低的PC*/
  @media (min-width: 992px) and (max-width: 1199px) {
    .course-task {
      .task {
        box-shadow: 1px 5px 29px 0px rgba(22, 116, 255, 0.16);
        margin-top: 38px;
      }
    }
  }

  .course-task {
    overflow: hidden;
    padding: 38px 37px 0px 29px;
    background: #fff;

    // 每一项作业
    .task {
      // width: 1134px;
      height: 147px;
      background: #ffffff;
      // border: 1px solid transparent;
      border-radius: 10px;
      padding-left: 34px;
      padding-right: 46px;
      box-sizing: border-box;
      margin-bottom: 51px;
      background-image: url("../../../assets/images/course/taskbg.png");
      background-repeat: no-repeat;
    }
    
    // 标题
    .task-title {
      padding-top: 27px;
      font-size: 20px;
      font-weight: 600;
      color: #343434;
    }

    .task-gap {
      // .task-introduce {
      //    line-height: 18px;
      //    font-size: 18px;
      //    font-weight: 400;
      //    color: #666;
      // }

      .task-btn {
        .btn {
          box-shadow: 1px 5px 13px 0px rgba(22, 116, 255, 0.28);
          border: 1px solid transparent;
          border-radius: 5px;
          width: 148px;
          line-height: 39px;

          color: #fff;
          font-size: 17px;
          font-weight: 500;

          background: linear-gradient(90deg, #2373ec, #3a7bf6);
        }

        .btn-finish {
          box-shadow:none;
          border: 1px solid #2674ed;
          color: #2674ed;
          background: transparent;
        }
      }
    } 

    // 时间 、 状态
    .task-time {
      font-size: 18px;
      font-weight: 400;
      color: #666;

      .status {
        margin-left: 22px;
        border: 1px solid #666;
        width: 66px;
        line-height: 26px;

        font-size: 15px;
        font-weight: 300;
        color: #6e6e6e;
      }

      .doing {
        border: 1px solid #FF0000;
        color: #ff0000;
      }
    }

    // 分页
    .pagination {
      margin-bottom: 40px;
    }
  }
 
</style>