import { render, staticRenderFns } from "./course_discuss.vue?vue&type=template&id=123d80fe&scoped=true"
import script from "./course_discuss.vue?vue&type=script&lang=js"
export * from "./course_discuss.vue?vue&type=script&lang=js"
import style0 from "./course_discuss.vue?vue&type=style&index=0&id=123d80fe&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123d80fe",
  null
  
)

export default component.exports