<template>
  <div class="comment-each">
    <div class="comment-people flex-align">
      <img :src="downloadURL+ comment.avatar" alt="" :onerror="$store.state.user.defaultAvatar" class="avatar" />
      <div class="people-info">
        <p class="name">{{comment.userName}}</p>
        <p class="time">{{comment.createTime}}</p>
      </div>
    </div>
    <div class="comment-text" ref="commentText">
      <div class="comment-text-inner" ref="commentTextInner">
        {{comment.content}}
      </div>
    </div>
    <button @click="flod()" ref="flodBtn" class="flod csp">{{isFlod?'[收起评论]':'[展开评论]'}}</button>
    <div class="comment-toolbar">
      <button class="comment-btn replay csp" @click="clickReply(comment)">回复</button>
      <button class="comment-btn delete csp" @click="clickDelete(comment)">删除</button>
    </div>
    <!-- 留言回复框 -->
    <div class="textarea-container parent-textarea" v-if="$store.state.discuss.replyArea === comment.id">
      <el-input type="textarea" :rows="2" placeholder="回复：" resize="none" v-model="textarea" class="textarea">
      </el-input>
      <div class="control-textarea">
        <el-button round size="mini" @click="activeComment = {};$store.state.discuss.replyArea = -1">取 消</el-button>
        <el-button round size="mini" type="primary" @click="submitReply(activeComment)">提 交</el-button>
      </div>
    </div>
    <div class="child-comment" v-for="(item,index) in childrenList" :key="index">
      <div class="child-item">
        <!-- 个人头像、回复信息 -->
        <div class="person-info flex-start">
          <img :src="downloadURL+item.userAvatar" alt="" class="child-avatar"
            :onerror="$store.state.user.defaultAvatar">
          <div class="child-content">
            <p class="f16 responder">{{item.userName}} <span style="color:#2373EC"> 回复 </span>
              {{item.parentUserName}}：</p>
            <p class="child-text">{{item.content}}</p>
            <p class="time">{{item.createTime}}</p>
          </div>
        </div>
        <!-- 右侧工具栏 -->
      </div>
      <div class="child-toolbar">
        <button class="btn" @click="childReply(item)">回复</button>
        <button class="btn" v-if="item.userName === userInfo.name" @click="clickDelete(item)">删除</button>
      </div>
      <!-- 子级留言回复框 -->
      <div class="textarea-container" v-if="$store.state.discuss.replyArea === item.id">
        <el-input type="textarea" :rows="2" placeholder="回复：" resize="none" v-model="textarea" class="textarea">
        </el-input>
        <div class="control-textarea">
          <el-button round size="mini" @click="childActiveComment = {};$store.state.discuss.replyArea = -1">取 消</el-button>
          <el-button round size="mini" type="primary" @click="submitReply(item)">提 交</el-button>
        </div>
      </div>
    </div>
    <!-- 删除 -->
    <div class="mask" v-show="isDeleteShow" @click="isDeleteShow = false">
      <div class="el-message-box">
        <div class="el-message-box__header">
          <div class="el-message-box__title">
            <span>提示</span>
          </div>
          <button type="button" aria-label="Close" class="el-message-box__headerbtn">
            <i class="el-message-box__close el-icon-close"></i>
          </button>
        </div>
        <div class="el-message-box__content">
          <div class="el-message-box__container">
            <div class="el-message-box__status el-icon-warning"></div>
            <div class="el-message-box__message">
              <p>是否删除该留言?</p>
            </div>
          </div>
        </div>
        <div class="el-message-box__btns">
          <el-button type="button" round size="mini" class="el-button el-button--default el-button--small"
            @click="$message('已取消删除')">
            取消
          </el-button>
          <el-button type="button" round size="mini" @click="deleteReply"
            class="el-button el-button--default el-button--small el-button--primary ">
            确定
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    inject: ['reload'],
    props: {
      eachComment: {
        type: Object,
        default: {}
      }
    },
    data() {
      return {
        childrenList: [],
        isDeleteShow: false,
        isReplyShow: false,
        textarea: '',
        parentId: 0,
        activeComment: {},
        childActiveComment: {},
        replyList: [],
        userInfo: this.$store.state.user.userInfo, //个人信息
        deleteId: null,
        isFlod: false,
      }
    },
    watch: {
      eachComment: {
        handler(newVal, oldVal) {
          // console.log(newVal);
          // if(JSON.stringify(newVal) == '{}') return;
          this.comment = newVal;
          this.childrenList = [];
          this.init();
        },
        immediate: true,
        deep: true
      },
    },
    methods: {
      //页面初始化
      init() {
        this.checkIfFlodBtn();
        if (this.comment.children === "") return
        this.handleComment(this.comment.children);
      },
      // 处理讨论列表
      handleComment(array) {
        let _this = this
        if (array.length > 0 && array.length) {
          array.forEach(function (item, index) {
            _this.childrenList = _this.childrenList.concat(item);
            _this.handleComment(item.children);
          });
        } else {
          return
        }
      },
      clickDelete(item) {
        this.deleteId = item.id
        this.isDeleteShow = true;
        // this.$emit('del',true);
      },
      clickReply(item) {
        this.$store.state.discuss.replyArea = item.id
        this.activeComment = item;
        this.childActiveComment = {};
      },
      // 子留言回复
      childReply(item) {
        // console.log(item);
        this.$store.state.discuss.replyArea = item.id
        this.childActiveComment = item;
        this.activeComment = {}
      },
      // 提交回复
      async submitReply(item) {
        if (!this.textarea.trim()) {
          this.$alert('填写内容不可为空', '提示', {
            confirmButtonText: '确定',
          });
          return
        }
        let params = {
          courseId: this.$route.params.id || 1,
          parentId: item.id,
          content: this.textarea,
        }
        // console.log(params);
        let resData = await this.$Api.Course.getcreate(params);

        if (resData.code === 200 && resData.msg === "请求成功") {
          this.$message.success('回复成功');
          // this.currentReply = 0;
          this.activeComment = {}
          this.childActiveComment = {};
          this.textarea = '';
          setTimeout(() => {
            this.$store.state.discuss.replyArea = -1
            this.$emit("refreshRequest", true);
            // this.reload();
            // this.reload();
          }, 100)
        } else {
          this.$message.error(resData.msg);
        }
      },
      //删除留言
      deleteReply() {
        this.$Api.Course.commentDel(this.deleteId).then((resData) => {
          // console.log(resData)
          if (resData.code === 200 && resData.msg === "请求成功") {
            this.$message.success('删除成功');
            this.$emit("refreshRequest", true);
            // this.$emit('del',false);
            // this.reload();
          } else {
            this.$message.error(resData.msg);
          }
        })
      },
      checkIfFlodBtn() {
        this.$nextTick(() => {
          if (this.$refs[`commentTextInner`].clientHeight <= 90) {
            this.$refs[`flodBtn`].style.display = 'none';
          }
        })
      },
      // 展开评论
      flod() {
        this.isFlod = !this.isFlod;
        // console.log(this.$refs[`commentTextInner`]);
        this.$refs[`commentText`].style.maxHeight = this.isFlod ?
          `${this.$refs[`commentTextInner`].clientHeight}px` : '90px';
      }

    },
    mounted() {
      // this.init();
    }

  }
</script>

<style lang="less" scoped>
  // .comment-everyone {
  // position: relative;
  // box-sizing: border-box;
  // margin-top: 25px;
  // width: 279px;
  // // height: 370px;
  // // min-height: 210px;
  // // max-height: 290px;
  // padding: 0 20px 15px;
  // overflow-y: auto;
  // background-color: #fff;
  // border: 1px solid #f2f2f2;
  // border-radius: 8px;

  // /*修改滚动条样式*/
  // &::-webkit-scrollbar {
  //   width: 8px;
  // }

  // &::-webkit-scrollbar-track {
  //   border-radius: 2px;
  // }

  // &::-webkit-scrollbar-button {
  //   height: 8px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: #bfbfbf;
  //   border-radius: 4px;
  // }

  // h4 {
  //   line-height: 40px;
  //   font-size: 13px;
  //   color: #555;
  //   border-bottom: 1px solid #f9f9f9;

  //   span {
  //     font-size: 12px;
  //     align-self: flex-end;
  //   }
  // }

  .comment-each {
    border-bottom: 1px solid #f9f9f9;
    padding-top: 18px;
    padding-bottom: 10px;

    .avatar {
      width: 29px;
      height: 29px;
      border-radius: 50%;
      margin-right: 8px;
    }

    .people-info .name {
      font-size: 13px;
      color: #555;
    }

    .people-info .time {
      font-size: 12px;
      color: #999999;
      line-height: 20px;
    }

    .comment-text {
      margin-top: 15px;
      color: #999999;
      font-size: 12px;
      padding-left: 5px;
      max-height: 90px;
      overflow: hidden;
      -webkit-transition: max-height .6s;
      -moz-transition: max-height .6s;
      -ms-transition: max-height .6s;
      -o-transition: max-height .6s;
      transition: max-height .6s;

      // -webkit-line-clamp: 5;
      .comment-text-inner {}
    }

    .flod {
      color: #666;
      padding-left: 7px;
      margin-top: 5px;
      font-size: 12px;
    }

    .comment-toolbar {
      text-align: right;
    }

    .comment-btn {
      font-size: 12px;
      color: #999999;

      &:last-child {
        margin-left: 18px;
      }
    }
  }

  .textarea-container {
    margin-top: 10px;
    text-align: right;
    font-size: 12px;

    .control-textarea {
      margin-top: 10px;
    }
  }

  .child-comment {
    .child-item {
      background-color: #f9f9f9;
      padding: 10px 13px 5px 17px;
      border-radius: 3px;
      margin-top: 10px;
    }

    .child-avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 9px;
    }

    .person-info,
    .child-content {
      flex: 1;
    }

    .responder,
    .child-text,
    .time {
      font-size: 12px;
      color: #999;
    }

    .child-text {
      margin-top: 5px;
      // -webkit-line-clamp: 5;
      max-height: 90px;
      overflow-y: auto;

      /*修改滚动条样式*/
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 2px;
      }

      &::-webkit-scrollbar-button {
        height: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 4px;
      }
    }

    .time {
      margin-top: 10px;
      text-align: right;
    }

    .child-toolbar {
      text-align: right;
      margin-top: 6px;

      .btn {
        cursor: pointer;
        font-size: 12px;
        color: #999;
      }

      .btn:last-child {
        margin-left: 22px;
      }
    }
  }

  .mask {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .3);
    padding: 25px 20px 0;
    box-sizing: border-box;
    text-align: right;

    /deep/ .el-textarea__inner {
      overflow: auto;

      /*修改滚动条样式*/
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 2px;
      }

      &::-webkit-scrollbar-button {
        height: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 4px;
      }
    }

    .publish-btn {
      margin-top: 15px;
      background-color: #508EF9;
      border: 1px solid #508EF9;
    }

    .el-message-box {
      width: 100%;
    }
  }

  /deep/.nothing-wrap {
    margin: 17px auto 0;
  }

  @media screen and (min-width:1250px) {
    width: 340px;
    padding: 0 25px;
    padding-bottom: 20px;

    h4 {
      font-size: 16px;
    }

    .comment-each {
      .avatar {
        width: 34px;
        height: 35px;
        margin-right: 10px;
      }

      .comment-text {
        font-size: 13px;
      }

      .comment-toolbar {
        text-align: right;
      }
    }
  }

  // }

  .of-h {
    overflow: hidden !important;
  }
</style>