<template>
  <div class="comment" :class="{'of-h':isMaskShow || isDeleteShow}">
    <h4 class="flex-align-between">评论
      <span class="csp" @click="isMaskShow = true">发表</span>
    </h4>
    <div class="comment-body">
      <div class="comment-each" v-for="(item,index) in commentList" :key="index">
        <each :eachComment="item" @refreshRequest="requestAgain" @del="isDelete"></each>
      </div>
      <Empty :show="commentList && commentList.length === 0" :text="'暂无评论'"></Empty>
    </div>
    <!-- 写留言 -->
    <div class="mask" v-show="isMaskShow" @click="isMaskShow = false">
      <i class="el-icon-close csp" @click="isMaskShow = false" style=""></i>
      <div @click.stop>
        <el-input class="textarea" type="textarea" :rows="7" resize="none" placeholder="写留言" v-model="massage"
          :maxlength="255" show-word-limit>
        </el-input>
      </div>
      <el-button type="primary" round size="mini" class="publish-btn" @click.stop="submitComment">确认发表</el-button>
    </div>
  </div>
</template>

<script>
  import empty from '../../../common/community/empty.vue'
  import each from './course_comment_each.vue'
  export default {
    components: {
      empty,
      each
    },
    data() {
      return {
        commentList: [], //评论数据列表
        isMaskShow: false, //是否展示留言遮罩层
        isDeleteShow: false, //删除状态
        massage: '', //留言内容
      }
    },
    methods: {
      // 根据组件回传值判断是否重新请求列表
      requestAgain(data) {
        // console.log(data);
        if (!data) return;
        this.getDiscussList();
      },
      // 根据组件回传值对评论区整体进行控制
      isDelete(data) {
        // this.isDeleteShow = data;
      },
      // 请求评论列表
      async getDiscussList() {
        let courseId = this.$route.params.id
        let resData = await this.$Api.Course.getCommentList(courseId)
        // console.log(resData);
        this.commentList = [];
        this.commentList = resData.data;
      },
      // 发表留言
      async submitComment() {
        if (this.massage.trim() == '') {
          this.$message.error('请填写留言');
          return
        }
        let params = {
          courseId: this.$route.params.id,
          parentId: this.parentId || 0,
          content: this.massage,
        }
        // console.log(params);
        let resData = await this.$Api.Course.getcreate(params);
        if (resData.code === 200 && resData.msg === "请求成功") {
          this.$message.success('提交留言成功');
          this.isMaskShow = false;
          this.massage = '';
          this.getDiscussList();
        } else {
          this.$message.error(resData.msg);
        }
      },
    },
    mounted() {
      this.getDiscussList();
    }

  }
</script>

<style lang="less" scoped>
  .comment {
    position: relative;
    box-sizing: border-box;
    margin-top: 25px;
    width: 279px;
    height: 370px;
    min-height: 210px;
    max-height: 290px;
    padding: 0 20px 15px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #f2f2f2;
    border-radius: 8px;

    /*修改滚动条样式*/
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 2px;
    }

    &::-webkit-scrollbar-button {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 4px;
    }

    h4 {
      line-height: 40px;
      font-size: 13px;
      color: #555;
      border-bottom: 1px solid #f9f9f9;

      span {
        font-size: 12px;
        align-self: flex-end;
      }
    }

    .mask {
      position: absolute;
      z-index: 99;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, .5);
      padding: 15px 20px 0;
      box-sizing: border-box;
      text-align: right;

      .el-icon-close {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 8px;
      }

      /deep/ .el-textarea__inner {
        overflow: auto;

        /*修改滚动条样式*/
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 2px;
        }

        &::-webkit-scrollbar-button {
          height: 2px;
        }

        &::-webkit-scrollbar-thumb {
          background: #bfbfbf;
          border-radius: 4px;
        }
      }

      .publish-btn {
        margin-top: 15px;
        background-color: #508EF9;
        border: 1px solid #508EF9;
      }

      .el-message-box {
        width: 100%;
      }
    }

    /deep/ .nothing-box .nothing-wrap {
      margin: 17px auto 0;
    }

    @media screen and (min-width:1250px) {
      width: 340px;

      .mask .el-icon-close {
        font-size: 18px;
      }
    }
  }

  .of-h {
    overflow: hidden !important;
  }
</style>