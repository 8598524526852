import { render, staticRenderFns } from "./course_catalog.vue?vue&type=template&id=639cd236&scoped=true"
import script from "./course_catalog.vue?vue&type=script&lang=js"
export * from "./course_catalog.vue?vue&type=script&lang=js"
import style0 from "./course_catalog.vue?vue&type=style&index=0&id=639cd236&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639cd236",
  null
  
)

export default component.exports