<template>
  <!-- 课程详情页---目录部分 -->
  <div class="course-catalog">
    <div class="lesson" v-for="(item, index) in lessonList" :key="index" @click="selectLesson(item)">
      <!-- <div class="lesson-row" :class="[item.hasOwnProperty('lessonFiles') || item.hasOwnProperty('lessonQuests') ? '':'not-child']"> -->
      <!-- :class="{'not-child' : hasChild(item)}" -->
      <div class="lesson-row-wrapper flex-align-between">
        <!-- 课节名称 -->
        <div style="flex:1;margin-right:10px;">
          <div class="lesson-row">
            <div class="row-item wes-2 lesson-name">{{ item.name }}</div>
            <!-- 课节时间-->
            <div class="row-item lesson-time" :class="[item.lessonsPattern === 0 ? '' : 'lesson-time-async']">
              <span v-if="item.lessonsPattern !== 0">开放时间：</span>
              <span>{{ dayjs(item.lessonsBeginTime * 1000).format('YYYY.MM.DD HH:mm') }}</span>
              <span v-if="item.lessonsPattern === 0">{{ `-${dayjs(item.lessonsEndTime * 1000).format('HH:mm')}` }}</span>
              <i class="living-icon"
                v-if="markMap[item.mark].lessonStatus.text === '直播中' && item.lessonsPattern === 0"></i>
              <span :class="[markMap[item.mark].lessonStatus.class]" class="status"
                v-if="markMap[item.mark].lessonStatus.text !== '已开放'">{{ markMap[item.mark].lessonStatus.text }}</span>
            </div>
            <!--教师，助教 -->
            <!-- <div class="row-item flex-layout wes lesson-teacher"
          :class="[item.lessonsPattern === 0? '':'lesson-teacher-async']">
          <span class="wes" v-if="item.teacherName">授课教师：{{item.teacherName}}</span>
          <span class="wes" 
                v-if="item.assistantName && item.lessonsPattern === 0">
                助教：{{item.assistantName}}</span>

        </div> -->
          </div>
          <div class="time-count" v-if="item.lessonsPattern === 0">
            <span>直播学习时长: {{ parseInt(item.liveTime / 60) || 0 }} 分钟</span>
            <span>观看回放总时长: {{ parseInt(item.playbackTime / 60) || 0 }} 分钟</span>
            <div class="last">
              <span>回放完成时长:{{ parseInt((item.progress) / 60) || 0 }}分钟 {{ (item.progress) % 60 }} 秒</span>
              <span v-if="item.videoLength == -1">--</span>
              <span v-else>{{ item.videoLength ? (Number(item.progress) / Number(item.videoLength) * 100).toFixed(0) : 0
              }}%</span>
            </div>
          </div>
          <div class="time-count" v-if="item.lessonsPattern === 1">
            <span>观看总时长: {{ parseInt((item.playbackTime + item.liveTime) / 60) || 0 }}
              分钟 {{ (item.playbackTime + item.liveTime) % 60 }} 秒</span>
            <div class="last">
              <span>课程完成时长:{{ parseInt((item.progress) / 60) || 0 }}分钟 {{ (item.progress) % 60 }} 秒</span>
              <span v-if="item.videoLength == -1">--</span>
              <span v-else>{{ item.videoLength ? (Number(item.progress) / Number(item.videoLength) * 100).toFixed(0) : 0
              }}%</span>
            </div>
          </div>
        </div>
        <!-- 按钮 -->
        <div class="row-item lesson-btn">
          <button class="btn-blue" :class="[markMap[item.mark].class]" @click="golive(item)">
            {{ markMap[item.mark].text }}
          </button>
          <button class="btn-link" @click="openLiveURL(item)" :data-clipboard-text="item.liveUrl"
            v-if="($store.state.user.role == 'expert' || $store.state.user.role == 'assistant') && item.liveUrl">直播链接</button>
          <button class="btn-link" @click="openLiveURL(item)" :data-clipboard-text="item.pushUrl"
            v-if="($store.state.user.role == 'expert' || $store.state.user.role == 'assistant') && item.pushUrl">推流地址</button>
        </div>
      </div>
      <!-- 文件列表 -->
      <div class="files" v-if="item.lessonFiles || item.lessonQuests">
        <div class="file csp flex-align-between" v-for="(row, i) in item.lessonFiles" :key="i">
          <div @click="openFile(row)"><span class="type-name">【资料】</span>{{ row.fileName }}</div>
          <div @click="common.downloadFile(row)" class="download-icon">
            <img class="csp" src="@/assets/images/course/download.png" alt />
          </div>
        </div>
        <div class="work csp flex-align-between" v-for="(row, i) in item.lessonQuests" :key="i + 100">
          <p class="work-title wes">【作业】{{ row.qtitle }}</p>
          <p class="work-time">
            <span>{{ dayjs(row.startTime).format('MM.DD HH:mm') }} - {{ dayjs(row.endTime).format('MM.DD HH:mm') }}</span>
            <!-- 2020.09.01 -->
            <i class="work-status" :class="{ 'working': handleWorkSatatus(row) === '进行中' }">{{ handleWorkSatatus(row)
            }}</i>
          </p>
          <div class="work-go-wrapper">
            <button class="work-go csp" :class="{ 'nostart': !row.finishStudy }" @click="toDoWork(row, row.finishStudy)"
              v-if="handleWorkSatatus(row) !== '未开始'">
              <!-- 专家 只能查看 -->
              {{ $store.state.user.role == 'expert' ? '查看' : '' }}
              <!-- 非专家  进行中 / 已结束 已做作业 -->
              {{ $store.state.user.role != 'expert' && row.aid ? '已完成' : '' }}
              <!-- 非专家  进行中  未做作业 -->
              {{ $store.state.user.role != 'expert' && handleWorkSatatus(row) == '进行中' && !row.aid ? '去完成' : '' }}
              <!-- 非专家  已结束  未做作业 -->
              {{ $store.state.user.role != 'expert' && handleWorkSatatus(row) == '已结束' && !row.aid ? '查看' : '' }}
            </button>
          </div>

        </div>
      </div>
    </div>
    <!-- 无课节-->
    <Empty :show="lessonList && lessonList.length === 0" :text="'暂无课节'"></Empty>
    <ToLiveMode :isVisible="downloadVisible" :liveLessonId="liveLessonId" :classType="classType" top="15vh"
      @downClient="handleDownLoad">
    </ToLiveMode>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lessonList: [], //课节列表
      downloadVisible: false,
      liveLessonId: 0,
      classType: 0,
      markMap: this.$store.state.course.courseMarkMap,
      common: Common, // 公共方法引用
      copyLiveUrl: '', //复制的直播链接
    };
  },
  computed: {},
  components: {},
  filters: {
    // 统计数据文字对照

  },
  methods: {
    // not-child 样式
    hasChild(item) {
      if (!item.lessonFiles && !item.lessonQuests) return true;
      if (JSON.stringify(item.lessonFiles) !== '[]' || JSON.stringify(item.lessonQuests) !== '[]') return false;
    },
    handleWorkSatatus(row) {
      let stratTime = this.dayjs(row.startTime).unix();
      let endTime = this.dayjs(row.endTime).unix();
      let nowTime = this.dayjs().unix();
      if (nowTime < stratTime) {
        return '未开始'
      }
      if (nowTime >= stratTime && nowTime <= endTime) {
        return '进行中'
      }
      if (nowTime > endTime) {
        return '已结束'
      }

    },
    handleDownLoad(data) {
      // console.log(data);
      this.downloadVisible = data.visible;
    },
    // classin课程打开直播链接
    openLiveURL(item) {
      // window.open(item.liveUrl)
      let clipboard = new Clipboard('.btn-link')
      clipboard.on('success', e => {
        this.$message.success('复制直播链接成功');
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        this.$message.warning('该浏览器不支持复制');
        clipboard.destroy()
      })
    },
    // 打开文件（预览下载）
    openFile(item) {
      // window.open(`${this.downloadURL}${item.identifier}`)
      window.open(`${this.downloadURL}${item.fileIdentifier}`)
    },
    // 精准教学去上课
    async getPrecisionTeach(type) {
      let params = {
        id: 1
      }
      let resData = await this.$Api.Home.getAuthKey(params);
      // console.log(resData);
      window.open(`${resData.data.url}&ticket=${resData.data.ticket}&lesson=${type}`);
    },
    // 去做作业
    toDoWork(item, finished) {
      if (!finished) {
        this.$message({
          message: '需要看完课程后才能作答',
          type: 'warning'
        });
        return
      }
      let breadLength = this.$store.state.basics.breadList.length
      // console.log(item);
      // 专家去查看
      if (this.$store.state.user.role == 'expert') {
        this.$router.push({
          path: "/workManage",
          query: {
            themeId: item.tid,
            from: 'course',
            courseId: this.$route.params.id,
            index: this.$route.query.index,
            // createId: JSON.parse(sessionStorage.getItem("userinfo")).id,
            createId: this.$store.state.user.userInfo.id,
            breadNumber: breadLength + 1,
          },
        });
        return
      }
      //  || this.handleWorkSatatus(item) == '已结束' && !row.aid 
      // 非专家 已结束 未做作业
      if (this.handleWorkSatatus(item) == '已结束' && !item.aid) {
        this.$router.push({
          path: '/view_empty',
          query: {
            questionId: item.qid,
            courseId: this.$route.params.id,
            from: 'course',
            index: this.$route.query.index,
            breadNumber: breadLength + 1,
          }
        });
        return;
      }
      // 非专家 进行中/已完成  已完成作业
      if ((this.handleWorkSatatus(item) == '进行中' || this.handleWorkSatatus(item) == '已结束') && item.aid) {
        this.$router.push({
          path: '/view',
          query: {
            questionId: item.qid,
            courseId: this.$route.params.id,
            from: 'course',
            index: this.$route.query.index,
            breadNumber: breadLength + 1,
          }
        });
        return;
      }
      // themeType [1.作业 2.测试 3.问卷]
      // 非专家  进行中  去做作业
      let toPath = item.themeType === 1 ? '/answer' : '/confirm';
      this.$router.push({
        path: toPath,
        query: {
          questionId: item.qid,
          courseId: this.$route.params.id,
          from: 'course',
          index: this.$route.query.index,
          breadNumber: breadLength + 1,
        }
      });
    },
    //获取课程列表
    async getLessonList() {
      let id = this.$route.params.id;
      // let resData = await this.$Api.Course.getLessonList(id);
      let resData
      if (this.$store.state.user.role == 'expert') {
        resData = await this.$Api.Course.getLessonExpert(id);
      } else {
        resData = await this.$Api.Course.getLessonNewList(id);
      }
      console.log('课节列表:', resData);
      // getLessonExpert
      this.lessonList = resData.data;
      // 获取所有异步课授课老师名字
      let teachers = '';
      resData.data.map(item => {
        if (item.lessonsPattern == 1) {
          if (item.lecturerInfo) {
            teachers += JSON.parse(item.lecturerInfo).name + ' '
          }
        }
      });
      for (var i = 0; i < resData.data.length; i++) {
        if (resData.data[i].lessonsPattern == 1) {
          if (resData.data[i].lecturerInfo) {
            this.$emit('getAsyncTeacherInfo', JSON.parse(resData.data[i].lecturerInfo))
          } else {
            this.$emit('getAsyncTeacherInfo', { name: '', introduce: '', })
          }
          break
        }
      }
      // console.log('teachers:',teachers);
      this.$emit('getAsyncTeacherName', teachers);

    },
    //跳转到百家云视频、异步课视频
    async golive(item) {
      console.log('-----', item);
      console.log('window.qt:', window.qt)
      // classin直播课客户端内
      if (window.qt && item.classType == 5) {
        console.log('=========')
        window.nativeBridge.initConfig.connect(function (config) {
          initConfig(config);
        });
        let params = {
          lessonId: item.id
        }
        // console.log(params);
        let resData = await this.$Api.Course.getClassInLivingInfo(params)
        let school = 632500; // 正式
        // let school = 101134; // 测试
        let zone = 86
        let classtype = 2
        let telephone = resData.data.phone;
        let course = resData.data.courseId;
        let lesson = resData.data.lessonId;
        if (item.mark == 2) {
          window.nativeBridge.openClassroom(zone, telephone, school, course, lesson, classtype)
        }
        return;
      }
      // classin直播课链接打开
      if (item.classType == 5 && item.mark == 2) {
        let resData = await this.$Api.Project.live(item.id);
        console.log('33333333', resData);
        if (resData.data.webLiveUrl) {
          window.open(resData.data.webLiveUrl);
          return
        }
      }
      // 百家云同步课 & 本地异步课 & classin直播课页面唤起客户端
      switch (item.mark) {
        // 百家云同步课（直播课）
        case 2: { // 直播中
          this.classType = item.classType;
          this.liveLessonId = item.id;
          this.downloadVisible = true;
          break;
        }
        // 百家云同步课观看回放 & 本地异步课
        case 4: //直播课观看回放
        case 11: { //异步课开始学习
          this.getLiveUrl(item.id).then((res) => {
            // 课节回放地址错误 提示
            if (!res) {
              this.$alert('该课节下暂无内容', '提示', {
                confirmButtonText: '确定'
              });
            }
            // 课程回放地址正确 去看视频
            if (res) {
              let breadLength = this.$store.state.basics.breadList.length
              this.$router.push({
                // path: '/asynclive',
                path: '/newasyncLive',
                query: {
                  id: item.id,
                  index: this.$route.query.index,
                  from: this.$route.query.from,
                  breadNumber: breadLength + 1,
                }
              });
            }
          })
          break;
        }
        default: {
          break;
        }
      }
    },
    // 获取课节回放地址
    async getLiveUrl(lessonId) {
      // 获取课节视频地址
      let resData = await this.$Api.Course.getLessonVideoUrl(lessonId);
      // console.log(resData)
      return resData.code == 500 ? false : true;
    },
    // 选择课节
    selectLesson(item) {
      // console.log('选择课节:', item);
      if (item.lessonsPattern == 1) {
        if (item.lecturerInfo) {
          this.$emit('getAsyncTeacherInfo', JSON.parse(item.lecturerInfo))
        } else {
          this.$emit('getAsyncTeacherInfo', { name: '', introduce: '', })
        }
      }
    }
  },
  created() { },
  mounted() {
    this.getLessonList();
  },
  activated() { },
};
</script>

<style lang="less" scoped>
.course-catalog {
  background-color: #fff;
  padding: 21px 4px 50px 19px;

  .lesson {
    margin-bottom: 28px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #FAFAFA;
  }

  .lesson-row-wrapper {
    background-color: #F6F6F6;
    border-radius: 10px;
    padding-right: 10px;
  }

  .time-count {
    padding: 5px 12px 7px 17px;
    font-size: 12px;
    flex-shrink: 0;
    font-family: Source Han Sans CN;
    color: #999999;
    border-radius: 10px;
    display: flex;
    align-items: center;

    .last {
      margin-left: auto;
    }

    span:first-child {
      margin-right: 30px;
    }
  }

  // 开始学习按钮
  .lesson-btn {
    position: relative;

    // flex-basis: 14%;
    .btn-blue {
      border-radius: 25px;
      line-height: 24px;

      color: #fff;
      font-size: 12px;
      font-weight: 500;
      padding: 0 17px;

      background-color: #508EF9;
      cursor: pointer;
    }

    .btn-grey {
      background: #c0c0c0;
    }

    .btn-link {
      // position: absolute;
      display: block;
      width: 100%;
      text-align: center;
      margin-top: 15px;
      font-size: 12px;
      color: #508EF9;
      cursor: pointer;

      &:hover {
        color: rgba(80, 142, 249, .7);
      }
    }
  }

  // 课节行 start
  .lesson-row {
    // background-color: #F6F6F6;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    flex: 1;
    padding: 10px 12px 7px 17px;
    border-radius: 10px;


    &.not-child {
      border-radius: 10px;
    }

    // 标题
    .lesson-name {
      font-weight: bold;
      font-size: 12px;
      // width: 140px;
      width: 230px;
      min-height: 42px;
      // padding: 10px 0;
      // flex-basis: 27%;
    }

    // 时间
    .lesson-time {
      // flex-basis: 28%;
      font-size: 12px;
      flex-shrink: 0;
      font-family: Source Han Sans CN;
      color: #999999;

      .status {
        display: inline-block;
        margin-left: 12px;
        border: 1px solid #999999;
        padding: 0 7px;
        line-height: 15px;
        border-radius: 25px;

        color: #999999;
        font-size: 12px;

        text-align: center;
        vertical-align: bottom;
      }

      .living {
        margin-left: 0px;
        padding: 0;
        border: 1px solid transparent;
        padding-right: 6px;
        width: auto !important;

        color: #ff0000;
      }

      .el-icon-s-data {
        font-size: 14px;
        color: #f00;
      }


      .living-icon {
        display: inline-block;
        background-image: url("~@/assets/images/home/vidicon.png");
        width: 14px;
        height: 10px;
        margin: 0 2px 0 11px;
      }
    }

    // 教师
    .lesson-teacher {
      // flex-basis: 25%;
      color: #666666;
      font-size: 12px;

      span:first-child {
        flex-shrink: 0;
      }

      span:nth-child(2) {
        margin-left: 15px;
      }
    }
  }

  // 课节行 end


  // 文件列表start
  .files {
    padding: 0 12px 0 19px;
    margin-left: -7px;
    overflow: hidden;

    .file,
    .work {
      margin: 17px 0;
      font-size: 12px;
      color: #333;

      &:hover {
        color: #508EF9;
      }

      .download-icon {
        width: 82px;
        text-align: center;
      }
    }

    .work {

      .work-title {
        width: 240px;
      }

      .work-time {
        color: #999;
      }

      .work-status {
        border: 1px solid #999999;
        padding: 0 7px;
        border-radius: 25px;
        color: #999999;
        margin-left: 12px;
        font-size: 12px;

        &.working {
          color: #508EF9;
          border: 1px solid #508EF9;
        }
      }

      .work-go-wrapper {
        min-width: 82px;
        text-align: center;
      }

      .work-go {
        width: 68px;
        border: 1px solid #508EF9;
        padding: 0 10px;
        border-radius: 25px;
        color: #508EF9;
        line-height: 19px;
        font-size: 12px;

        &:hover {
          background-color: #508EF9;
          color: #fff;
        }
      }

      .nostart {
        color: #ccc;
        border: 1px solid #ccc;

        &:hover {
          background-color: #ccc;
          color: #fff;
        }
      }

    }
  }

  // 文件列表end

  /*媒体查询*/
  @media screen and (min-width:1250px) {
    padding: 26px 5px 50px 23px;

    .time-count {
      display: flex;
      align-items: center;
      padding: 10px 15px 12px 21px;
      font-size: 13px;

      .last {
        margin-left: auto;
      }
    }

    .lesson-btn {
      .btn-blue {
        line-height: 29px;

        font-size: 13px;
        padding: 0 20px;

      }

      .btn-link {
        margin-top: 20px;
      }
    }

    .lesson-row-wrapper {
      padding-right: 21px;
    }

    .lesson-row {
      // padding: 12px 15px 9px 21px;
      padding: 15px 15px 12px 21px;

      .lesson-name {
        font-size: 14px;
        width: 280px;
      }

      .lesson-time {
        font-size: 13px;
      }

      .lesson-teacher {
        font-size: 13px;
      }


    }

    .files {
      padding: 0 15px 0 23px;
      overflow: hidden;

      .file {
        font-size: 14px;

        .download-icon {
          width: 92px;
        }
      }

      .work {
        font-size: 14px;

        .work-title {
          width: 300px;
        }

        .work-time {
          font-size: 13px;
          width: 222px;
        }

        .work-status {
          padding: 0 8px;
          margin-left: 12px;
          font-size: 12px;
        }

        .work-go-wrapper {
          min-width: 92px;
          text-align: center;
        }

        .work-go {
          padding: 0 12px;
          line-height: 21px;
          font-size: 13px;
        }
      }
    }


  }

}
</style>