<template>
  <!-- 课程详情页---简介部分 -->
  <div class="course-introduction">
    <div class="introduce-part">
      <p v-html="courseDesc.courseExplain && courseDesc.courseExplain"></p>
    </div>
    <Empty :show="!courseDesc.courseExplain"></Empty>
  </div>
</template>

<script>
  export default {
    props: ['courseDesc'],
    data() {
      return {}
    }
  };
</script>

<style lang="less" scoped>
  .course-introduction {
    box-sizing: border-box;
    padding: 20px 25px;
    background-color: #fff;
    line-height: 34px;
    color: #595959;
    font-size: 16px;
    word-wrap: break-word;
    word-break: break-all;
  }
</style>