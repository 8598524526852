<template>
  <!-- 课程详情页---目录部分 -->
  <div class="course-achievement">
    <div class="achievement-wrapper">
      <div class="file-list">
        <h3 class="list-title">教学设计 10</h3>
        <div class="files flex-between">
          <div class="file flex-align csp" v-for="(item, index) in teachList" :key="index"
            @click="openPreview(item,true)">
            <img :src="item.cover" alt="" class="file-cover">
            <div class="file-introduction">
              <p class="file-title">{{item.title}}</p>
              <div class="flex-align-between">
                <span class="file-views">{{item.teacher}}/{{item.views}}人预览</span>
                <div class="file-toolbar">
                  <el-tooltip class="item csp" effect="dark" content="关系图谱" placement="top">
                    <i class="el-icon-connection" @click.stop="openRelation(item)" style="margin-right:8px;"></i>
                    <!-- @click="openRelation(item)" -->
                  </el-tooltip>
                  <el-tooltip class="item csp" effect="dark" content="下载" placement="top">
                    <i class="el-icon-download"></i>
                    <!--  @click="common.downloadFile(item)" -->
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="file-list">
        <h3 class="list-title">反思图 10</h3>
        <div class="files flex-between">
          <div class="file flex-align reflect-file csp" v-for="(item, index) in reflectList" :key="index"
            @click="openPreview(item)">
            <img :src="item.cover" alt="" class="file-cover reflect-cover">
            <div class="file-introduction">
              <p class="file-title">{{item.title}}</p>
              <div class="flex-align-between">
                <span class="file-views">{{item.teacher}}/{{item.views}}人预览</span>
                <div class="file-toolbar">
                  <el-tooltip class="item csp" effect="dark" content="关系图谱" placement="top">
                    <i class="el-icon-connection" @click.stop="openRelation(item)" style="margin-right:8px;"></i>
                    <!-- @click="openRelation(item)" -->
                  </el-tooltip>
                  <el-tooltip class="item csp" effect="dark" content="下载" placement="top">
                    <i class="el-icon-download"></i>
                    <!--  @click="common.downloadFile(item)" -->
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="file-list">
        <h3 class="list-title">教学课例 10</h3>
        <div class="files flex-between ">
          <div class="file flex-align course-file csp" v-for="(item, index) in courseList" :key="index"
            @click="openPreview(item,true)">
            <img :src="item.cover" alt="" class="file-cover course-cover">
            <div class="file-introduction">
              <p class="file-title">{{item.title}}</p>
              <div class="flex-align-between">
                <span class="file-views">{{item.teacher}}/{{item.views}}人预览</span>
                <div class="file-toolbar">
                  <el-tooltip class="item csp" effect="dark" content="关系图谱" placement="top">
                    <i class="el-icon-connection" @click.stop="openRelation(item)" style="margin-right:8px;"></i>
                    <!-- @click="openRelation(item)" -->
                  </el-tooltip>
                  <el-tooltip class="item csp" effect="dark" content="下载" placement="top">
                    <i class="el-icon-download"></i>
                    <!--  @click="common.downloadFile(item)" -->
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 无课节-->
    <!-- <Empty :show="lessonList && lessonList.length === 0" :text="'暂无内容'"></Empty> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        teachList: [{
            title: '《平行四边形的面积》教学设计_刘佳颖',
            teacher: '刘佳颖',
            views: 40,
            cover: require("@/assets/images/course/word.png")
          },
          {
            title: '《平行四边形的面积》教学设计_董艳萍',
            teacher: '董艳萍',
            views: 25,
            cover: require("@/assets/images/course/word.png")
          },
          {
            title: '《平行四边形的面积》教学设计_张小瑞',
            teacher: '张小瑞',
            views: 22,
            cover: require("@/assets/images/course/word.png")
          },
          {
            title: '《平行四边形的面积》教学设计_王蒙',
            teacher: '王蒙',
            views: 10,
            cover: require("@/assets/images/course/word.png")
          },
          {
            title: '《平行四边形的面积》教学设计_张迪',
            teacher: '张迪',
            views: 15,
            cover: require("@/assets/images/course/word.png")
          },
          {
            title: '《平行四边形的面积》教学设计_宋冬冬',
            teacher: '宋冬冬',
            views: 12,
            cover: require("@/assets/images/course/word.png")
          },
          {
            title: '《平行四边形的面积》教学设计_曹然',
            teacher: '曹然',
            views: 10,
            cover: require("@/assets/images/course/word.png")
          },
          {
            title: '《平行四边形的面积》教学设计_张连珍',
            teacher: '张连珍',
            views: 10,
            cover: require("@/assets/images/course/word.png")
          },
          {
            title: '《平行四边形的面积》教学设计_郑倩',
            teacher: '郑倩',
            views: 12,
            cover: require("@/assets/images/course/word.png")
          },
          {
            title: '《平行四边形的面积》教学设计_赵阳',
            teacher: '赵阳',
            views: 13,
            cover: require("@/assets/images/course/word.png")
          },
        ],
        reflectList: [{
            title: '《平行四边形的面积》反思图_刘佳颖',
            teacher: '刘佳颖',
            views: 39,
            cover: require("@/assets/images/course/achievement1.png")
          },
          {
            title: '《平行四边形的面积》反思图_董艳萍',
            teacher: '董艳萍',
            views: 28,
            cover: require("@/assets/images/course/achievement2.png")
          },
          {
            title: '《平行四边形的面积》反思图_张小瑞',
            teacher: '张小瑞',
            views: 26,
            cover: require("@/assets/images/course/achievement3.png")
          },
          {
            title: '《平行四边形的面积》反思图_王蒙',
            teacher: '王蒙',
            views: 14,
            cover: require("@/assets/images/course/achievement4.png")
          },
          {
            title: '《平行四边形的面积》反思图_张迪',
            teacher: '张迪',
            views: 18,
            cover: require("@/assets/images/course/achievement5.png")
          },
          {
            title: '《平行四边形的面积》反思图_宋冬冬',
            teacher: '宋冬冬',
            views: 15,
            cover: require("@/assets/images/course/achievement6.png")
          },
          {
            title: '《平行四边形的面积》反思图_曹然',
            teacher: '曹然',
            views: 12,
            cover: require("@/assets/images/course/achievement7.png")
          },
          {
            title: '《平行四边形的面积》反思图_张连珍',
            teacher: '张连珍',
            views: 11,
            cover: require("@/assets/images/course/achievement8.png")
          },
          {
            title: '《平行四边形的面积》反思图_郑倩',
            teacher: '郑倩',
            views: 17,
            cover: require("@/assets/images/course/achievement9.png")
          },
          {
            title: '《平行四边形的面积》反思图_赵阳',
            teacher: '赵阳',
            views: 15,
            cover: require("@/assets/images/course/achievement10.png")
          },
        ],
        courseList: [{
            title: '《平行四边形的面积》教学课例_刘佳颖',
            teacher: '刘佳颖',
            views: 54,
            cover: require("@/assets/images/course/achievement-course1.png")
          },
          {
            title: '《平行四边形的面积》教学课例_董艳萍',
            teacher: '董艳萍',
            views: 46,
            cover: require("@/assets/images/course/achievement-course2.png")
          },
          {
            title: '《平行四边形的面积》教学课例_张小瑞',
            teacher: '张小瑞',
            views: 22,
            cover: require("@/assets/images/course/achievement-course3.png")
          },
          {
            title: '《平行四边形的面积》教学课例_王蒙',
            teacher: '王蒙',
            views: 19,
            cover: require("@/assets/images/course/achievement-course4.png")
          },
          {
            title: '《平行四边形的面积》教学课例_张迪',
            teacher: '张迪',
            views: 31,
            cover: require("@/assets/images/course/achievement-course5.png")
          },
          {
            title: '《平行四边形的面积》教学课例_宋冬冬',
            teacher: '宋冬冬',
            views: 28,
            cover: require("@/assets/images/course/achievement-course6.png")
          },
          {
            title: '《平行四边形的面积》教学课例_曹然',
            teacher: '曹然',
            views: 20,
            cover: require("@/assets/images/course/achievement-course7.png")
          },
          {
            title: '《平行四边形的面积》教学课例_张连珍',
            teacher: '张连珍',
            views: 32,
            cover: require("@/assets/images/course/achievement-course8.png")
          },
          {
            title: '《平行四边形的面积》教学课例_郑倩',
            teacher: '郑倩',
            views: 18,
            cover: require("@/assets/images/course/achievement-course9.png")
          },
          {
            title: '《平行四边形的面积》教学课例_赵阳',
            teacher: '赵阳',
            views: 17,
            cover: require("@/assets/images/course/achievement-course10.png")
          },
        ],
      };
    },
    computed: {},
    components: {},
    methods: {
      openPreview(file, nonPreview) {
        if (nonPreview) return;
        window.open(require("@/assets/images/course/preview.jpg"));
      },
      // 查询文件关系图谱
      openRelation() {
        this.$router.push({
          path: '/resources',
          query: {
            achievement: 'achievement',
          },
        })
      },
    },
    created() {},
    mounted() {},
    activated() {},
  };
</script>

<style lang="less" scoped>
  .course-achievement {
    background-color: #fff;
    padding: 5px 24px 50px 24px;

    .list-title {
      font-size: 13px;
      color: #000;
      font-weight: 600;
      margin-bottom: 28px;

      &::before {
        content: ' ';
        display: inline-block;
        margin-right: 7px;
        width: 2px;
        height: 12px;
        background-color: #508EF9;
        border-radius: 3px;
        vertical-align: -1px;
      }
    }

    .files {
      padding: 0 0 8px 10px;
      flex-flow: row wrap;
    }

    .file {
      flex-shrink: 0;
      margin-bottom: 22px;
      width: 48%;

      .file-cover {
        max-width: 100%;
        width: 14px;
        margin-right: 12px;

        &.reflect-cover,
        &.course-cover {
          width: 57px;
        }
      }

      .file-introduction {
        width: 100%;
      }

      .file-title {
        margin-bottom: 3px;
        font-size: 12px;
        color: #555555;
        font-weight: 600;
      }

      .file-views {
        font-size: 12px;
        color: #999;
      }

      .file-toolbar i {
        font-size: 13px;
      }
    }

    /*媒体查询*/
    @media screen and (min-width:1250px) {
      padding: 10px 29px 50px 29px;

      .list-title {
        font-size: 16px;
        margin-bottom: 38px;

        &::before {
          margin-right: 10px;
          width: 3px;
          height: 15px;
        }
      }

      .files {
        padding: 0 0 10px 14px;
      }

      .file {
        margin-bottom: 28px;
        width: 44%;

        .file-cover {
          width: 17px;
          margin-right: 15px;

          &.reflect-cover,
          &.course-cover {
            width: 69px;
          }
        }

        .file-title {
          margin-bottom: 6px;
          font-size: 13px;
        }

        .file-views {
          font-size: 13px;
        }

        .file-toolbar i {
          font-size: 16px;
        }
      }

    }

  }
</style>